export default {
  dragonegg: {
    43113: '',
    43114: '0x88c090496125b751b4e3ce4d3fdb8e47dd079c57',
  },
  masterChef: {
    43113: '',
    43114: '0xC0F19836931F5Ab43f279D4DD5Ab3089846Db264',
  },
  wavax: {
    43113: '',
    43114: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
  },
  lottery: {
    43113: '',
    43114: '',
  },
  lotteryNFT: {
    43113: '',
    43114: '',
  },
  mulltiCall: {
    43113: '',
    43114: '0x8be4CB8cc385C9aDE8b307753cC68aBcFcf4cDCE',
  },
  usdte: {
    43113: '',
    43114: '0xc7198437980c041c805a1edcba50c1ce5db95118',
  },
}
